import { NgModule } from '@angular/core';
import { DatePipe, DecimalPipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';

// Import containers
import {
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
} from './containers';


import { IconModule, IconSetService } from '@coreui/icons-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptor/token.interceptor'
import { CacheInterceptor } from './core/interceptor/cache.interceptor'
import { LogoutModule } from './modules/logout/logout.module';
import { LoginModule } from './modules/login/login.module';
import { RulesModule } from './modules/rules/rules.module';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from './shared/shared.module';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { MessageService } from 'primeng/api';

const oktaAuth = new OktaAuth({
  issuer: environment.okta_issuer,
  clientId: environment.okta_client_id,
  redirectUri: environment.okta_redirect_uri
});

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
];

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IconModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule,
    OktaAuthModule

  ],
  providers: [
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    IconSetService,
    Title,
    DatePipe,
    MessageService,
    DecimalPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
