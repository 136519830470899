import { Injectable } from '@angular/core';
import { LocalService } from './local.service';

const TOKEN = "webToken";
const USER_INFO = "webUserInfo";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private localService: LocalService) { }

  isLoggedIn() {
    const token = this.localService.getJsonValue(TOKEN);
    const userInfo = this.localService.getJsonValue(USER_INFO);
    if(!token || !userInfo){
      console.error('Unauthenticated');
      return false;
    }

    return true;
  }

  getUserRole(){
    const userInfo = this.localService.getJsonValue(USER_INFO);
    const userInfoObject = JSON.parse(userInfo);
    return userInfoObject.role;
  }

}

