<!-- <ng-container *ngIf="!isOktaLogin else okta">
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
      <c-container>
          <c-row class="justify-content-center">
              <c-col md="8">
                  <c-card-group>
                      <c-card class="p-4">
                          <c-card-body>
                              <form cForm>
                                  <h1>AMWAY E-TAX INVOICE</h1>
                                  <p class="text-medium-emphasis">Sign In to your account</p>
                                  <c-input-group class="mb-3">
                                      <span cInputGroupText>
                                          <svg cIcon name="cilUser"></svg>
                                      </span>
                                      <input name="username" autoComplete="username" cFormControl
                                          placeholder="Username" [(ngModel)]="username" />
                                  </c-input-group>
                                  <c-input-group class="mb-4">
                                      <span cInputGroupText>
                                          <svg cIcon name="cilLockLocked"></svg>
                                      </span>
                                      <input name="password" autoComplete="current-password" cFormControl
                                          placeholder="Password" type="password" [(ngModel)]="password" />
                                  </c-input-group>
                                  <c-row>
                                      <c-col xs="6">
                                          <button cButton class="px-4" color="primary" (click)="login()">
                                              Login
                                          </button>
                                          <br />
                                          <img *ngIf="isWaiting" src="../../../assets/img/wait.gif" class="m-1" />
                                      </c-col>
                                  </c-row>
                              </form>
                          </c-card-body>
                      </c-card>
                  </c-card-group>
              </c-col>
          </c-row>
      </c-container>
  </div>
</ng-container>
<ng-template #okta>
  <div class='fade show' style='text-align: center; padding-top: calc(100vh / 2); height: 100vh'>
      <i class='spinner-grow spinner-grow-sm'></i>
      <span class='m-1'>Loading...</span>
  </div>
</ng-template>
<app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal> -->
<ng-container>
  <div class="bg-light min-vh-100 d-flex flex-row align-items-center">
      <c-container>
          <c-row class="justify-content-center">
              <c-col md="8">
                  <c-card-group>
                      <c-card class="p-4">
                          <c-card-body>
                              <form cForm>
                                  <h1>AMWAY BLACK MARKET</h1>
                                  <h5>Logged Out</h5>
                                  <c-row class="mt-5">
                                    <c-col xs="6">
                                        <button cButton class="px-4" color="primary" (click)="signin()">
                                            Sign in Again
                                        </button>
                                    </c-col>
                                </c-row>
                                  <!-- <p class="text-medium-emphasis">Sign In to your account</p>
                                  <c-input-group class="mb-3">
                                      <span cInputGroupText>
                                          <svg cIcon name="cilUser"></svg>
                                      </span>
                                      <input name="username" autoComplete="username" cFormControl
                                          placeholder="Username" />
                                  </c-input-group>
                                  <c-input-group class="mb-4">
                                      <span cInputGroupText>
                                          <svg cIcon name="cilLockLocked"></svg>
                                      </span>
                                      <input name="password" autoComplete="current-password" cFormControl
                                          placeholder="Password" type="password" />
                                  </c-input-group>
                                  <c-row>
                                      <c-col xs="6">
                                          <button cButton class="px-4" color="primary">
                                              Login
                                          </button>
                                          <br />
                                          <img src="../../../assets/img/wait.gif" class="m-1" />
                                      </c-col>
                                  </c-row> -->
                              </form>
                          </c-card-body>
                      </c-card>
                  </c-card-group>
              </c-col>
          </c-row>
      </c-container>
  </div>
</ng-container>
<ng-template #okta>
  <div class='fade show' style='text-align: center; padding-top: calc(100vh / 2); height: 100vh'>
      <i class='spinner-grow spinner-grow-sm'></i>
      <span class='m-1'>Loading...</span>
  </div>
</ng-template>
<!-- <app-message-alert-modal [messageAlert]="messageAlert"></app-message-alert-modal> -->

