import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';

import { DefaultLayoutComponent } from './containers';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './modules/login/login.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { ConfigModule } from './modules/config/config.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login/callback', component: OktaCallbackComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'rules',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/rules/rules.module').then((m) => m.RulesModule),
      },
      {
        path: 'monitoring',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/monitoring/monitoring.module').then((m) => m.MonitoringModule),
      },
      {
        path: 'dashboard',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'inquiry',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/inquiry/inquiry.module').then((m) => m.InquiryModule),
      },
      {
        path: 'users',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/user-role/user-role.module').then((m) => m.UserRoleModule),
      },
      {
        path: 'reconcile',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/reconcile/reconcile.module').then((m) => m.ReconcileModule),
      },
      {
        path: 'config',
        canLoad: [AuthGuard],
        loadChildren: () =>
          import('./modules/config/config.module').then((m) => m.ConfigModule),
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Logout Page'
    },
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
