import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';

import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  ModalModule,
  NavModule,
  ProgressModule,
  SharedModule as CoreUISharedModule,
  SidebarModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';
import { AgGridModule } from 'ag-grid-angular';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {MultiSelectModule} from 'primeng/multiselect';
import { NgxSpinnerModule } from "ngx-spinner";
import {TableModule} from 'primeng/table';
import { DropdownModule as  DropdownModulePrimeng} from 'primeng/dropdown';
@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    CoreUISharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
    AgGridModule,
    ModalModule,
    FormsModule,
    AutoCompleteModule,
    DialogModule,
    ToastModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    MultiSelectModule,
    NgxSpinnerModule,
    TableModule,
    DropdownModulePrimeng
  ],
  exports: [
    CommonModule,
    AvatarModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonGroupModule,
    ButtonModule,
    CardModule,
    DropdownModule,
    FooterModule,
    FormModule,
    GridModule,
    HeaderModule,
    ListGroupModule,
    NavModule,
    ProgressModule,
    CoreUISharedModule,
    SidebarModule,
    TabsModule,
    UtilitiesModule,
    AgGridModule,
    ModalModule,
    PaginationComponent,
    FormsModule,
    AutoCompleteModule,
    DialogModule,
    ToastModule,
    CalendarModule,
    CheckboxModule,
    ChipsModule,
    MultiSelectModule,
    NgxSpinnerModule,
    TableModule,
    DropdownModulePrimeng
  ]
})
export class SharedModule { }
