import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { LocalService } from './local.service';
import { UserInfo, UserPerm } from '../models/auth/user.model';

var CryptoJS = require("crypto-js");
const TOKEN = "webToken";
const USER_INFO = "webUserInfo";
const HYBRIS_TOKEN = "hybrisToken";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private localService: LocalService) { }

  private formatErrors(error: any) {
    return throwError(error);
  }

  getToken() {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded",
    );

    const env: any = environment;
    const body = {
      grantType: "client_credentials",
      clientId: env.client_id,
      clientSecret: env.client_secret
    };

    return this.http
      .post(
        `${env.base_bo_api_bmk}/webauthorize/token`,
        body,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }

  aesEncrypt(plainText: string) {
    const env: any = environment;
    const parsedkey = CryptoJS.enc.Utf8.parse(env.secret_key);
    const iv = CryptoJS.enc.Utf8.parse(env.secret_iv);
    const encrypted = CryptoJS.AES.encrypt(plainText, parsedkey, { iv: iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.toString(CryptoJS.format.Hex);
  };

  hashGenerate(data: any) {
    const env: any = environment;
    let hash = CryptoJS.SHA256(data + env.salt).toString();
    return hash;
  }

  hashVerify(data: any, hash: any) {
    const env: any = environment;
    let hashVerify = CryptoJS.SHA256(data + env.salt).toString();
    return hashVerify == hash;
  }

  login(username: string, password: string) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let encData = this.aesEncrypt(JSON.stringify(
      {
        "uName": username,
        "uPass": password,
        "expireTimestamp": moment(new Date(new Date().getTime() + (60 * 60 * 1000))).format(),
      }
    ));

    const body = {
      "encData": encData,
      "hashData": this.hashGenerate(encData)
    }

    return this.http
      .post(
        `${environment.bo_api_bmk}/login`,
        body,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }

  setJwt(token: any) {
    this.localService.setJsonValue(TOKEN, JSON.stringify(token));
  }

  getJwt() {
    return JSON.parse(this.localService.getJsonValue(TOKEN));
  }

  getHybrisToken() {
    return this.localService.getJsonValue(HYBRIS_TOKEN);
  }

  setHybrisToken(token: any) {
    this.localService.setJsonValue(HYBRIS_TOKEN, token);
  }

  getUserInfo(): any {
    return <any>JSON.parse(this.localService.getJsonValue(USER_INFO));
  }

  setUserInfo(user: UserInfo) {
    this.localService.setJsonValue(USER_INFO, JSON.stringify(user));
  }

  // getUserPerm(): UserPerm[] {
  //   return <UserPerm[]>JSON.parse(this.localService.getJsonValue(USER_PERM));
  // }

  // setUserPerm(perm: UserPerm[]) {
  //   this.localService.setJsonValue(USER_PERM, JSON.stringify(perm));
  // }

  getUserByUserName(oktaClaims: any) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );

    let encData = this.aesEncrypt(JSON.stringify(
      {
        "userName": oktaClaims.userName,
        "fullName": oktaClaims.fullName,
        "email": oktaClaims.email,
        "profileName": oktaClaims.profileName,
        "expireTimestamp": moment(new Date(new Date().getTime() + (60 * 60 * 1000))).format(),
      }
    ));

    const body = {
      "encData": encData,
      "hashData": this.hashGenerate(encData)
    }

    const env: any = environment;

    return this.http
      .post(
        `${environment.bo_api_bmk}/userinfo`,
        body,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }

  isAuthenticated(){
    const token = this.localService.getJsonValue(TOKEN);
    const userInfo = this.localService.getJsonValue(USER_INFO);
    if(!token || !userInfo){
      console.log('Unauthenticated');
      return false;
    }

    return true;
  }

  logout() {
    this.localService.remove(TOKEN);
    this.localService.remove(HYBRIS_TOKEN);
    this.localService.remove(USER_INFO);
  }
}
