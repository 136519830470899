import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HybrisService {

  constructor(private http: HttpClient) { }

  getToken() {
    const params = new HttpParams()
      .set('client_id', environment.hybris_client_id)
      .set('client_secret', environment.hybris_client_secret)
      .set('grant_type', environment.hybris_grant_type)
    return this.http.post(`${environment.hybris_api}/authorizationserver/oauth/token?${params.toString()}`, null);
  }

  getProvinces() {
    return this.http.get(`${environment.hybris_api}/api/v2/amwaythailand/address-suggest/province`);
  }

  getDistricts(provinceCode?: string) {
    return this.http.get(`${environment.hybris_api}/api/v2/amwaythailand/address-suggest/district?province=${provinceCode}`);
  }

  getSubDistricts(district?: string, provinceCode?: string){
    return this.http.get(`${environment.hybris_api}/api/v2/amwaythailand/address-suggest/subdistrict?district=${district}&province=${provinceCode}`);
  }

  getPostalCodes(subDistrict?: string, district?: string, provinceCode?: string, ){
    return this.http.get(`${environment.hybris_api}/api/v2/amwaythailand/address-suggest/postalcode?subDistrict=${subDistrict}&province=${provinceCode}&district=${district}`);
  }

  getProduct(sku: string){
    return this.http.get(`${environment.hybris_api}/api/v2/amwaythailand/products/${sku.toLocaleUpperCase()}`);
  }
}
