import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, from, Observable, of, tap, throwError } from 'rxjs';
import { LocalService } from '../services/local.service';
import { HybrisService } from '../services/hybris.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const TOKEN = "webToken";
const HYBRIS_TOKEN = "hybrisToken";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private localService: LocalService, private router: Router, private hybrisService: HybrisService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // `${environment.bo_api_bmk}`
    // `https://ft67flaf6l.execute-api.ap-southeast-1.amazonaws.com/uat/apibo`
    if (request.url.startsWith(`${environment.base_bo_api_bmk}/apibo`)) {
      const boApiToken = JSON.parse(this.localService.getJsonValue(TOKEN));

      if (!boApiToken) {
        this.router.navigate(['/login']);
      }

      const authRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${boApiToken}`)
      })

      return next.handle(authRequest).pipe(
        catchError(error => {
          if ((error instanceof HttpResponse || error instanceof HttpErrorResponse) && error.status == 401) {
            this.localService.remove(TOKEN);
            this.router.navigate(['/login']);
          }
          return throwError(() => error);
        })
      );

    } else if (request.url.startsWith(`${environment.hybris_api}/api`)) {
      const hybrisToken = this.localService.getJsonValue(HYBRIS_TOKEN);

      if (!hybrisToken) {
        this.router.navigate(['/login']);
      }

      const authRequest = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${hybrisToken}`)
      })
      return next.handle(authRequest).pipe(
        catchError(error => {
          if ((error instanceof HttpResponse || error instanceof HttpErrorResponse) && error.status == 401) {
            this.localService.remove(HYBRIS_TOKEN);
            this.router.navigate(['/login']);
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(request);
  }
}
