<c-row>
    <c-col [sm]="12">
        <span>Showing {{getShowingFrom()}} to {{getShowingTo()}} of
            {{pageinfo.totalRecord}} entries</span>
        <c-button-toolbar class="float-end" role="group">
            <c-button-group class="me-2" role="group">
                <button cButton color="primary" [disabled]="pageinfo.page <= 1" (click)="gotoPage(pageinfo.page - 1)">
                    {{'<<'}} </button>
                        <button cButton color="primary" *ngIf="pageNumber[0] > 1" (click)="gotoPage(pageNumber[0] - 1)">
                            {{'...'}}
                        </button>
                        <button cButton color="primary" *ngFor="let n of pageNumber" [disabled]="pageinfo.page == n"
                            (click)="gotoPage(n)">
                            {{n}}
                        </button>
                        <button cButton color="primary" *ngIf="pageNumber[pageNumber.length - 1] != pageinfo.allpage"
                            (click)="gotoPage(pageNumber[pageNumber.length - 1] + 1)">
                            {{'...'}}
                        </button>
                        <button cButton color="primary" [disabled]="pageinfo.page >= pageinfo.allpage"
                            (click)="gotoPage(pageinfo.page + 1)">
                            {{'>>'}}
                        </button>
            </c-button-group>
        </c-button-toolbar>
    </c-col>
</c-row>
