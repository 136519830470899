import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  private cache = new Map<string, HttpResponse<any>>();

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if(request.method == 'GET'){
    //   console.log(request);

    //   const cachedResponse = this.cache.get(request.urlWithParams);
    //   console.log(cachedResponse);
    //   if(cachedResponse){
    //     return of(cachedResponse);
    //   }else{
    //     return next.handle(request).pipe(
    //       tap(event => {
    //         if(event instanceof HttpResponse){
    //           this.cache.set(request.url, event)
    //         }
    //       })
    //     )
    //   }
    // }

    return next.handle(request);
  }
}
