import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { HeaderNavigateModel } from 'src/app/core/models/headernavigate.model';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  headerNavigateModelList: Array<HeaderNavigateModel> = new Array<HeaderNavigateModel>();
  userInfo: any = null;

  constructor(
    private classToggler: ClassToggleService,
    private router: Router,
    private userService: UserService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) {
    super();
    this.userInfo = this.userService.getUserInfo();
  }

  async logout() {
    this.userService.logout();
    if (environment.okta_login) {
      this._oktaAuth.tokenManager.clear();
      this.router.navigate(['/logout']);
      return;
    }
    this.router.navigate(['/logout']);
  }
}
