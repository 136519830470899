import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [

  {
    name: 'Reconcile',
    url: '/reconcile'
  },
  {
    name: 'Monitoring',
    url: '/monitoring'
  },
  {
    name: 'Inquiry',
    url: '/inquiry',
    iconComponent: { name: 'cil-list' },
    class:"menu_inquiry",
    children: [
      {
        name: 'Sales/Returns',
        url: '/inquiry/salesreturns',
        linkProps: {
          routerLinkActiveOptions: { exact: false }
        },
        class: "submenu_salesreturns"
      },
      {
        name: 'Serial Tracking',
        url: '/inquiry/serialtracking',
        linkProps: {
          routerLinkActiveOptions: { exact: false }
        },
        class: "submenu_serialtracking"
      },
      {
        name: 'Distributor Address',
        url: '/inquiry/distributoraddress',
        linkProps: {
          routerLinkActiveOptions: { exact: false }
        },
        class: "submenu_distributoraddress"
      },
      {
        name: 'MTD by Product Group',
        url: '/inquiry/mtdbyproductgroup',
        linkProps: {
          routerLinkActiveOptions: { exact: false }
        },
        class: "submenu_mtdbyproductgroup"
      },
      {
        name: 'Credit',
        url: '/inquiry/credit',
        linkProps: {
          routerLinkActiveOptions: { exact: false }
        },
        class: "submenu_credit"
      }
    ]
  },
  {
    name: 'Rule Setting',
    url: '/rules',
  },
  {
    name: 'User',
    url: '/users'
  },
  {
      name: 'Config',
      url: '/config',
  }
];
