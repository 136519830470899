<!-- <ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1 text-light">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <img class="ps-2" height="30px" src="../../../../assets/img/amway-logo.png" alt="">
    <c-header-nav class="d-none d-lg-flex me-auto">
    </c-header-nav>
    <c-header-nav class="d-none d-lg-flex">
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-2">
        <li class="breadcrumb-item" *ngFor="let item of headerNavigateModelList; index as i; last as isLast"
          [ngClass]="{'active': isLast}">
          <a *ngIf="!isLast" class="text-light" [routerLink]="item.url">{{item.label}}</a>
          <label *ngIf="isLast" class="text-secondary">{{item.label}}</label>
        </li>
      </ol>
    </nav>
  </c-container>
</ng-container>
<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <label class="text-light me-2" *ngIf="userInfo"><strong>{{userInfo.profileName}}</strong></label>
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/img/avatars/0.jpg" status="success">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <a (click)="logout()" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log out
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template> -->
<ng-container>
  <c-container [fluid]="true">
    <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1 text-light">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <img class="ps-2" height="30px" src="../../../../assets/img/amway-logo-003.png" alt="">
    <c-header-nav class="d-none d-lg-flex me-auto">
    </c-header-nav>
    <c-header-nav class="d-none d-lg-flex">
    </c-header-nav>
    <c-header-nav class="ms-3">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-2">
        <li class="breadcrumb-item" *ngFor="let item of headerNavigateModelList; index as i; last as isLast"
          [ngClass]="{'active': isLast}">
          <a *ngIf="!isLast" class="text-light" [routerLink]="item.url">{{item.label}}</a>
          <label *ngIf="isLast" class="text-secondary">{{item.label}}</label>
        </li>
      </ol>
    </nav>
  </c-container>
</ng-container>
<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
      <label class="text-light me-2" *ngIf="userInfo"><strong>{{userInfo.profileName}}</strong></label>
      <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/img/avatars/0.jpg" status="success">
      </c-avatar>
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
      </li>
      <li>
        <label  class="py-2 ps-3">Name : {{userInfo?.fullName}}</label>
    </li>

    <li>
        <label  class="py-2 ps-3">Role : {{userInfo?.role}}</label>
    </li>
      <li>
        <a (click)="logout()" cDropdownItem>
          <svg cIcon class="me-2" name="cilLockLocked"></svg>
          Log out
        </a>
      </li>
      <li></li>
    </ul>
  </c-dropdown>
</ng-template>

